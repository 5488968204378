// todos container
.todos {
  padding: 8px;
  text-align: center;
  color: var(--black);
  letter-spacing: normal;
  .content {
    position: absolute;
    top: 96px;
    left: 0px;
    padding: 0 18px 40px 18px;
    width: calc(100% - 36px);
    .todos-tableView {
      width: 100%;
      table {
        tbody {
          tr {
            td {
              padding-right: 40px;
              button.todo-removeAction {
                line-height: 0;
              }
              button.todo-gotoAction {
                line-height: 0;
              }
            }
            td:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
