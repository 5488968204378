body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* Global Colours */
  --topControlsBlue: #50aeff; // color: var(--topControlsBlue);
  --topControlsPurple: #f1e8ff; // color: var(--topControlsBlue);
  --topControlsOrange: #e69603; // color: var(--topControlsOrange);
  --topControlsGrey: #d0d0d0; // color: var(--topControlsGrey);

  --white: #ffffff; // color: var(--white);
  --black: #000000; // color: var(--white);

  /* Global Calculations*/
  --topControlsBuffer: 80px; // height: var(--topControlsHeight);
  --appsDrawerContainerCalc: calc(
    100vh - var(--topControlsBuffer)
  ); // height: var(--appsDrawerContainerCalc);
}

/* Apps Drawer Styles */
.renderAppsDrawer {
  display: block !important;
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.appsDrawer {
  display: none;
  visibility: none;
  /* types of app ViewContainers currently implemented include:
    - fullScreen
  */
  .appsDrawer-fullScreenViewContainer {
    position: absolute;
    width: 100vw;
    height: var(--appsDrawerContainerCalc);
    top: var(--topControlsBuffer);
    left: 0;
    background-color: var(--topControlsPurple);
    z-index: 999;
    .safeArea {
      padding: 10px;
    }
  }
}
