.workshop {
  background-color: black;
  color: #1fb5ff;
  width: 100vw;
  height: 100vh;
  letter-spacing: 1rem;
  user-select: unset;
  text-transform: uppercase;
  h1 {
    font-weight: 300;
  }
}
