/* Top Controls Styles */
// The top controls used for navigation in all views
.topControls {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  /* types of topControls currently implemented include:
    - rightOnly
    - leftAndRight
  */
  .topControls-rightOnly {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 10px;
    .label {
      padding: 8px;
      height: 100%;
      align-self: center;
    }
  }

  .topControls-leftAndRight {
    display: flex;

    justify-content: space-between;
    padding: 8px 10px;
    .label {
      padding: 8px;
      height: 100%;
      align-self: center;
    }
  }

  /* the generic button in the nav 
  usually calls an action to progress or contribute if on the right 
  if on the left it usually signals a backwarda navigation event */
  .topControls-button {
    height: 60px;
    width: 60px;
    background-color: var(--topControlsPurple);
    border: white 1px solid;
    line-height: 0;
    padding: 8px;
    border-radius: 6px;
  }
  .topControls-pencil::after {
    content: "✏️";
  }
  .topControls-plus::after {
    content: "➕";
  }
  .topControls-goal::after {
    content: "🥅";
  }
  .topControls-hundred::after {
    content: "💯";
  }
}
