.navbar-chizzele {
  background-color: black;
  color: #1fb5ff;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  width: 100vw;
  /* width: 0; */
  height: 0;
  top: 0;
  /* list-style: none; */
  left: 0;
}
